.App {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.side-bar {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100vh;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1), 0 2px 5px rgba(0,0,0,0.2);
  background-color: #24262B;
  color: whitesmoke;
}

.side-bar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 10px;
}

.side-bar-header h4 {
  margin: 0;
  margin-top: 20px;
  font-size: 14pt;
}

.side-bar-body {
  padding-left: 25px;
  padding-top: 20px;
}

#api-data {
  width: 90vw;
  padding-left: 30px;
  padding-top: 30px;
  overflow-y: scroll;
}

.api-link {
  font-size: 13pt;;
  padding: 5px 0;
  margin-bottom: 10px;
  cursor: pointer;
  color: #A4A6B3;
}
.api-link.selected {
  color: whitesmoke;
}